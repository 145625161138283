import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import pic01 from '../assets/images/pic01.jpg'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {
    return (
      <Layout>
        <Helmet title="Argosy Educational Support, LLC" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Who is behind Argosy Educational Support, LLC?</h2>
                </header>
                <p>
                Argosy Educational Support, LLC, is a one woman operation and a labor of love.  When you connect with Argosy, you benefit from the educational support provided by a person who:
                </p>
                <ol>
                  <li>Taught all subjects, preschool through high school, in DMV public and private schools for 13 years.</li>
                  <li>Accumulated two master’s degrees in education- one focusing on elementary education and the other on adult education.</li>
                  <li>Knows the expectations of higher ed, as a current part time professor.</li>
                  <li>Excels in communication and nurturing school/family relationships.</li>
                  <li>Believes in social justice and encouraging critical thinkers, change makers, and lifelong learners.</li>
                </ol>
                {/* <ul className="actions">
                  <li>
                    <Link to="/generic" className="button">
                      Learn More
                    </Link>
                  </li>
                </ul> */}
              </div>
              <span className="image">
                <img src="https://lh3.googleusercontent.com/a-/AOh14GjGBEc--64rE9qiD0YiiU3Icyvtko-JXK_Cr_AH=s300" alt="Jessica Hall, Founder of Argosy Education, LLC" />
              </span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>What I do</h2>
            </header>

            <ul className="Services-list">
              <li>Tutor students in all subjects, preschool through college</li>
              <li>Provide organizational and academic support for students who struggle with initiating work, understanding and/or following assignment directions, and following through</li>
              <li>Create access points to learning for differently abled students</li>
              <li>Remove or lighten the relationship strain between parents and children by offering outside support and rigor, as well as nurturing student discipline</li>
              <li>Help with homework, short term assignments, and long term assignments</li>
              <li>Enrich and engage advanced and high achieving students who are not challenged with their classes and/or school programming at THEIR level</li>
              <li>Collaborate with families on maximizing communication and relationships with their student’s teachers and schools</li>
              <li>Attend IEP meetings and Student Support Team meetings as a student and/or family advocate</li>
              <li>Make students smile and laugh while they learn and get their work done</li>
              <li>Encourage the development of student self esteem, as needed</li>
              <li>Develop students’ “eye for detail” and self-editing</li>
              <li>Increase student vocabulary, reading comprehension ability, and writing skills</li>
            </ul>
            {/* <ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Ipsum consequat</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
              <li>
                <span className="icon major style3 fa-copy"></span>
                <h3>Amed sed feugiat</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-diamond"></span>
                <h3>Dolor nullam</h3>
                <p>
                  Sed lorem amet ipsum dolor et amet nullam consequat a feugiat
                  consequat tempus veroeros sed consequat.
                </p>
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer> */}
          </section>

          {/* <section id="second" className="main special">
            <header className="major">
              <h2>Ipsum consequat</h2>
              <p>
                Donec imperdiet consequat consequat. Suspendisse feugiat congue
                <br />
                posuere. Nulla massa urna, fermentum eget quam aliquet.
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-code-fork"></span>
                <strong>5,120</strong> Etiam
              </li>
              <li className="style2">
                <span className="icon fa-folder-open-o"></span>
                <strong>8,192</strong> Magna
              </li>
              <li className="style3">
                <span className="icon fa-signal"></span>
                <strong>2,048</strong> Tempus
              </li>
              <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>4,096</strong> Aliquam
              </li>
              <li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>1,024</strong> Nullam
              </li>
            </ul>
            <p className="content">
              Nam elementum nisl et mi a commodo porttitor. Morbi sit amet nisl
              eu arcu faucibus hendrerit vel a risus. Nam a orci mi, elementum
              ac arcu sit amet, fermentum pellentesque et purus. Integer maximus
              varius lorem, sed convallis diam accumsan sed. Etiam porttitor
              placerat sapien, sed eleifend a enim pulvinar faucibus semper quis
              ut arcu. Ut non nisl a mollis est efficitur vestibulum. Integer
              eget purus nec nulla mattis et accumsan ut magna libero. Morbi
              auctor iaculis porttitor. Sed ut magna ac risus et hendrerit
              scelerisque. Praesent eleifend lacus in lectus aliquam porta. Cras
              eu ornare dui curabitur lacinia.
            </p>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul>
            </footer>
          </section> */}

          <section id="cta" className="main special">
            <header className="major">
              <h2>Let's Connect</h2>
              <p>
                Need more information or to speak with me directly about how I can help?
              </p>
            </header>
            <footer className="major">
            <a href="mailto:jesshalldc@gmail.com?subject=Argosy Educational Support Inquiry" className="button special">Get in touch</a>
              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button special">
                    Get Started
                  </Link>
                </li>
                <li>
                  <Link to="/generic" className="button">
                    Learn More
                  </Link>
                </li>
              </ul> */}
            </footer>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Index
